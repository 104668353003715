@import url("https://fonts.googleapis.com/css?family=Chivo");

.App {
  text-align: center;
  font-family: "Chivo";
  color:white;
}

* {
  box-sizing: border-box;
}

.menu a {
  text-align: center;
  font-family: "Chivo";
  color:white;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

body {
  background-image: url("./assets/background/mint-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

.logo {
  max-width:90%;
}